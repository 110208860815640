.product {
    background-color: #051516;
    color: white;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.how-section {
    background-color: white;
}

.how-section .container {
    max-width: 620px;
    margin: auto;
}

.how-section .title {
    color: #051516;
    text-align: center;
}

.how-section img {
    width: 100%;
}


/* Benefits section */
.benefits-section .body {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
}

.benefits-section .title {
    color: #00e9be;
}

.benefits-section .benefit {
    max-width: 220px;
}

.benefits-section img {
    width: 100%;
}


/* Key features */
#key-features {
    background-color: white;
    color: black;
}

#key-features img {
    max-width: 360px;
}

.title img {
    width: 26px;
    vertical-align: bottom;
}

#key-features .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
    margin-bottom: 2rem;
}

#key-features .row .content {
    margin-bottom: 2rem;
}

#key-features .row .title {
    margin-top: 1rem;
    margin-bottom: 1.5rem !important;
    color: #00e9be;
    font-size: 1.5rem;
    font-weight: normal;
}

#key-features .content {
    max-width: 360px;
}

.benefits-title {
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
    color: #00e9be;
}

.title-product {
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
}

.title-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.performance {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.icon {
    margin-right: 5px;
}
