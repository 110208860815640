body {
    background-color: none !important;
}
.how-section {
    background-color: white;
}

.button {
    width: max-content;
    padding: 0.4rem 1.8rem;
    color: black;
    background-color: #00e9be;
    border: 0;
    border-radius: 16px;
}

.how-section .container {
    max-width: 620px;
    margin: auto;
    border-radius: 20px;
}

.how-section .title {
    color: #000 !important;
    text-align: center;
    margin-bottom: 4rem !important;
    border-radius: 10px 10px 0px 0px;
}

.how-section img {
    width: 100%;
}

.position {
    color: #051516;
}

.requirements {
    color: #051516;
}
