.header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #153843;
    padding: 1rem;
}

.header a {
    display: inline-block;
}

.header .selected {
    border-bottom: 3px solid #00e9be;
}

.yokozuna-logo {
    max-width: 110px;
    display: inline-block;
    margin-right: 3rem;
}

.navbar {
    display: inline-block;
}

.navbar a {
    text-decoration: none;
    font-size: 1.5rem;
    color: white;
    margin-right: 1rem;
    line-height: 28px;
}