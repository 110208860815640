.team {
    background-color: #051516;
    color: white;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}
.title {
    text-align: center;
    margin-bottom: 4rem !important;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
}

.role {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

#team {
    background-color: #00e9be;
    padding: 4em;
}

#team .title {
    font-size: 1.5rem;
    margin-bottom: 1rem !important;
    min-height: 58px;
    font-weight: normal;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 2rem;
}

.w-100 {
    width: 100%;
}

.member {
    margin-right: 2rem;
    text-align: center;
    min-width: 300px;
    max-width: 300px;
    margin-bottom: 2rem;
}

.member img {
    max-width: 130px;
    margin: 0 auto;
}

.row-main {
    margin-bottom: 0px !important;
}

.team-title {
    color: #fff;
    margin-top: 5px;
}

