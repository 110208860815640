.home {
    background-color: #051516;
    color: white;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.title {
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
}

.button {
    width: max-content;
    padding: 0.6rem 1.8rem;
    color: black;
    background-color: #00e9be;
    border: 0;
    border-radius: 16px;
}

.section {
    padding: 4rem;
}


/* First section */
.first-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    background-color: #153843;
}

.first-section .demo-gds {
    max-width: 400px;
    text-align: center;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.first-section .demo-gds img {
    max-width: 330px;
}

.first-section .button {
    display: inline-block;
    margin-top: 1rem;
    text-decoration: none;
}

.first-section .explanation {
    max-width: 400px;
}

.first-section .explanation div:first-child {
    margin-top: 0;
}

.first-section .explanation div {
    margin-top: 2.25rem;
}

.first-section .explanation h4 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    font-weight: normal;
}


/* Why section */
.why-section {
    background-color: #00e9be;
}

.why-section .body {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 3rem;
}

.why-section .info-container {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
}

.why-section h4 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: normal;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
}

.why-section p {
    text-align: center;
    color: black;
}

.why-section img {
    max-width: 170px;
}


/* In the news section */
.yd-news-section .title {
    color: #00e9be;
}

.yd-news-section .row {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.yd-news-section .row div {
    max-width: 200px;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.yd-news-section img {
    width: 80px;
}

.partners-section {
    background-color: #00e9be;
    padding: 4em;
}

.partners-section .title {
    color: black;
}

.partners-section .body {
    width: max-content;
    margin: auto;
    display: flex;
}

.partners-section img {
    max-width: 128px;
}

.partners-section a {
    text-decoration: none;
}

.keywords-section {
    background-color: #1b4856;
    padding: 4em;
    text-align: center;
}

.keywords-section .title {
    text-align: center;
    color: #fff;
}

.keywords-section img {
    width: 250px;
    margin: 0 auto;
}

.footer {
    background-color: #153843;
    text-align: right;
    padding: 1rem 3rem;
}

.explanation-content {
    text-align: left;
}

.header-text {
    line-height: 18px;
}

.content-left {
    line-height: 20px;
    margin-top: 14px;
}

.title-text {
    color: #fff;
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
}
