.loading {
  display: flex;
  justify-content: center;
  align-items: center;

  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #059669;
    // width: 30px;
    // height: 30px;
    animation: spin 2s linear infinite;
  }
}
  
/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
