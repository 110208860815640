.contact {
    .body {
        background-color: #153843;
    }
}

.contact-body {
    text-align: center;
    max-width: 500px;
    margin: auto;
    padding: 4rem;
    background-color: #153843;
    color: #fff;
}

.role {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.logos {
    display: flex;
    justify-content: center;
    align-items: center;
}

.title {
    color: #00e9be;
    margin-bottom: 2rem !important;
}

.office {
    margin-bottom: 4rem;
}

.news {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 3px solid #00e9be;
}

.hiring {
    margin-top: 3rem;
}

.content-title {
    color: #00e9be;
    margin-bottom: 2rem !important;
    text-align: center;
    margin-top: 0;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: 600;
}
